import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { Typography } from "@/uikit/Typography";

import {
  authorInfoStyle,
  companyIconWrapperStyle,
  quoteIconWrapperStyle,
  quoteImgStyle,
  quoteTextStyle,
  quoteWrapperStyle,
} from "./quote.css";

import { RemoteImage } from "@/uikit/remote-image";
import QuoteImg from "@/svg/icons/quote.svg";

const QuoteBlock = Block("quote", (props) => {
  const { text, author } = props;

  return (
    <BlockContainer defaultTheme="light" settings={props.settings} blockPaddingTop="medium" blockPaddingBottom="medium">
      <div className={quoteWrapperStyle}>
        <div className={quoteIconWrapperStyle}>
          <QuoteImg className={quoteImgStyle} />
        </div>
        <Typography className={quoteTextStyle} settings={{ tag: "p" }}>
          {text}
        </Typography>
        <div className={companyIconWrapperStyle}>
          <RemoteImage image={author.companyIcon} />
        </div>
        <Typography settings={{ tag: "p" }}>
          <Typography className={authorInfoStyle}>
            {author?.name} / {author.position}
          </Typography>
        </Typography>
      </div>
    </BlockContainer>
  );
});

export default QuoteBlock;
